import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { toastError } from '@/utils';

export const INVESTIGATION_SCOPES = {
  investigation: 'investigation',
  investigationBookings: 'investigationBookings',
  createInvestigation: 'newInvestigation',
  updateInvestigation: 'updateInvestigation',
};

const investigation = createCustomStore(({ runAsyncFlow }) => withScopes(INVESTIGATION_SCOPES, ({
  state: {
    [INVESTIGATION_SCOPES.investigation]: {
      data: null,
    },
    [INVESTIGATION_SCOPES.createInvestigation]: {
      data: null,
    },
    [INVESTIGATION_SCOPES.updateInvestigation]: {
      data: null,
    },
    [INVESTIGATION_SCOPES.investigationBookings]: {
      data: [],
    },
  },
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(
        state,
        payload,
        [INVESTIGATION_SCOPES.updateInvestigation, INVESTIGATION_SCOPES.createInvestigation],
      );
    },
  },
  actions: {
    async getInvestigation({ commit }, damageUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.investigation.getDamageInvestigation,
        params: [damageUuid],
        scope: INVESTIGATION_SCOPES.investigation,
      });
    },
    async getInvestigationBookings({ commit }, damageUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.bookings.getInvestigationBookings,
        params: [damageUuid],
        scope: INVESTIGATION_SCOPES.investigationBookings,
      });
    },
    async postInvestigation({ commit }, damageUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.investigation.postDamageInvestigation,
        params: [damageUuid],
        scope: INVESTIGATION_SCOPES.createInvestigation,
      });
    },
    async putInvestigation({ commit }, { damageUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.investigation.putDamageInvestigation,
        params: [damageUuid, data],
        scope: INVESTIGATION_SCOPES.updateInvestigation,
      });
    },
  },
})));

export default investigation;
